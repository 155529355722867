<template>
   <div class="content-wrapper">
      <section class="content">
         <div id="my-html-content">

            <div v-if="reports === null" class="d-flex justify-content-center">{{ $t('loading') }}</div>
            <div v-else>
               <h4 class="my-3" style="display: flex; flex-direction: column; align-items: center">
                  Relatório Financeiro Anual - {{ $route.params.year }}
               </h4>

               <table class="table table-bordered">
                  <thead>
                  <tr>
                     <th>Mês</th>
                     <th>Valor Arrecadado</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(report, index) in treatedReports" :key="index">
                     <td>{{ report.monthName }}</td>
                     <td>R$ {{ report.collectedAmount }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                     <td colspan="7"></td>
                  </tr>
                  <tr>
                     <td colspan="1"><b>TOTAL</b></td>
                     <td><b>R$ {{ collectedTotal }}</b></td>
                  </tr>
                  </tfoot>
               </table>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import reportService from "../services/report-service";
import { getMonthName } from "../../../utils/report-service-methods";
import html2pdf from "html2pdf.js/src";

export default {
   name: "ReportYearly",
   data() {
      return {
         reports: null,
         collectedTotal: 0
      };
   },
   computed: {
      treatedReports () {
         let treatedReports = [];
         Object.entries(this.reports).map(([key, value]) => {
            const month = key.split("-")[1]; // Split the key on the '-' and take the second part (the month);
            const monthName = getMonthName(month);
            treatedReports.push({ month: month, monthName: monthName, collectedAmount: value });
         });

         treatedReports = treatedReports.sort((a, b) => a.month - b.month);

         treatedReports.forEach((report) => {
            this.collectedTotal += report.collectedAmount;
         });
         treatedReports = this.formatCurrency(treatedReports);

         return treatedReports;
      }
   },
   async created() {

      this.reports = await reportService.reportYearly(this.$route.params.year);

      setTimeout(() => {
         const element = document.getElementById('my-html-content');
         const opt = {
            margin:       0.5,
            filename:     'relatorio.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 5 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
         };
         const pdf = html2pdf().from(element).set(opt);
         pdf.save(); // save the PDF

         window.print();
      }, 1000)
   },
   methods: {
      formatCurrency (reports) {
         reports.map((report) => {
            report.collectedAmount = report.collectedAmount.toLocaleString('pt-br', {minimumFractionDigits: 2});
         });
         this.collectedTotal = this.collectedTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});

         return reports;
      }
   }
}
</script>

<style scoped>

</style>
